<template>
  <div
    class="pointer-events-none fixed bottom-16 left-1/2 z-toast flex w-11/12 -translate-x-1/2 flex-col sm:w-fit lg:bottom-0"
  >
    <TransitionGroup
      name="list-complete"
      mode="out-in"
      tag="div"
      class="mx-auto flex flex-col items-center"
    >
      <UiToastMessage
        v-for="systemMessage in toastMessages"
        :key="systemMessage.id"
        :message="systemMessage.message"
        :type="systemMessage.type"
        :closable="systemMessage.closable"
        :timeout="systemMessage.timeout"
        class="mb-2 transition-all"
        @close="closeNotification(systemMessage.id)"
      />
    </TransitionGroup>
  </div>
</template>

<script lang="ts" setup>
import UiToastMessage from '@ui/components/UiToastMessage/UiToastMessage.vue'
import { useNotification } from '../../composables/useNotifications'
import type { NotificationComponentType } from '../../stores/notifications.types'

const _componentType: NotificationComponentType
  = 'toast' as NotificationComponentType

const { toastMessages, closeNotification } = useNotification(_componentType)
</script>
